import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import classNames from 'classnames';

import RuleElement from 'components/molecules/RuleElement';

import { useScreenService } from 'hooks/useScreenService';

import {
  StyledButton,
  StyledDescription,
  StyledHeader,
  StyledHeaderContainer,
  StyledHouseRules,
  StyledImage,
  StyledRulesList,
  StyledWrapper,
} from './HouseRules.styles';

import { HouseRulesProps } from './models.d';

const HouseRules = ({ description, button, rules, image }: HouseRulesProps) => {
  const { isMobile, isMdUp } = useScreenService();

  return (
    <StyledHouseRules data-testid="house-rules">
      <StyledWrapper>
        <StyledHeaderContainer>
          <StyledHeader>
            {description ? (
              <StyledDescription
                size={{ base: 30, md: 36, lg: 48, xl: 60 }}
                lineHeight={1.25}
                fontWeight={300}
                letterSpacing="-0.02em"
                color="gray-900"
              >
                {renderRichText(description)}
              </StyledDescription>
            ) : null}
            {button && isMdUp ? (
              <StyledButton
                {...button}
                typography={{ padding: { left: 20, right: 20, top: 12, bottom: 12 } }}
              />
            ) : null}
          </StyledHeader>
        </StyledHeaderContainer>
        {rules ? (
          <StyledRulesList>
            {rules.map((rule, index) => (
              <RuleElement
                className={classNames({
                  'item-first': index === 0,
                  'item-last': index === rules.length - 1,
                })}
                key={rule.title}
                index={index}
                {...rule}
              />
            ))}
          </StyledRulesList>
        ) : null}
        {button && isMobile ? (
          <StyledButton
            {...button}
            typography={{ padding: { left: 20, right: 20, top: 12, bottom: 12 } }}
          />
        ) : null}
      </StyledWrapper>
      {image ? <StyledImage image={image.image} alt={image.alt} /> : null}
    </StyledHouseRules>
  );
};
export default HouseRules;
