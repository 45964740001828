import styled from 'styled-components';

export const StyledArrowButton = styled.button`
  position: absolute;

  &.swiper-button-prev,
  &.swiper-button-next {
    top: 40px;
    z-index: var(--z-index-three);
    width: 100px;
    height: 100px;
  }

  &::after {
    display: none;
  }

  &.swiper-left {
    left: 20px;
    justify-content: flex-start;
  }

  &.swiper-right {
    right: 20px;
    justify-content: flex-end;
  }

  .swiper-arrow {
    display: none;
  }

  &:focus-visible {
    outline: none;

    .swiper-arrow {
      display: block;
      width: 100px;
      height: 100px;
      padding: var(--gap-xxxs);
      border: 4px solid var(--c-darkmode-900);
      border-radius: 50%;
      outline: 2px solid var(--focus-color);
      outline-offset: 4px;

      path {
        /* important is neccessary */
        stroke: var(--c-darkmode-900) !important;
      }
    }
  }
`;

export const StyledArrowWrapper = styled.div`
  &.swiper-button-wrapper-prev,
  &.swiper-button-wrapper-next {
    position: absolute;
    top: 0;
    z-index: var(--z-index-three);
    width: 50%;
    height: 100%;
  }

  &.swiper-left {
    left: 20px;
    justify-content: flex-start;
  }

  &.swiper-right {
    right: 20px;
    justify-content: flex-end;
  }
`;

export const StyledSwiperWrapper = styled.div`
  padding: 0 var(--gap-xxs);

  .swiper {
    padding-top: var(--gap-xxxxs);
    padding-bottom: 96px;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid var(--c-gray-900);
    opacity: 1;

    &-active {
      background-color: var(--c-gray-900);
    }
  }

  .swiper-slide {
    cursor: pointer;
    opacity: 0.3;
    transition: opacity var(--transition);
  }

  .swiper-slide-active {
    opacity: 1;
  }
`;
