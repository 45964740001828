import { TypographyProps } from 'components/atoms/Typography';

export const opinionContentTypography: TypographyProps = {
  size: { base: 24, lg: 36 },
  lineHeight: 1.33,
  fontWeight: 400,
  align: 'center',
  color: 'gray-900',
  letterSpacing: '-0.02em',
};

export const opinionTitleTypography: TypographyProps = {
  size: 20,
  lineHeight: 1.6,
  fontWeight: 400,
  color: 'gray-900',
};

export const opinionSubtitleTypography: TypographyProps = {
  size: { base: 14, md: 16 },
  lineHeight: 1.5,
  fontWeight: 300,
  color: 'gray-600',
};
