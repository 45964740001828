import React, { useEffect, useState } from 'react';
import FadeIn from 'framers/FadeIn';
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import Opinion from 'components/molecules/Opinion';

import { swiperProps } from './OurOpinion.constants';
import { StyledArrowButton, StyledArrowWrapper, StyledSwiperWrapper } from './OurOpinion.styles';

import { OurOpinionType } from './models.d';

import 'swiper/css/bundle';

const previousOpinionButtonAriaLabel = 'Click to show previous opinion';
const nextOpinionButtonAriaLabel = 'Click to show next opinion';
const OurOpinion = ({ className, quote, title, variant = 'fade' }: OurOpinionType) => {
  const [isDragged, setIsDragged] = useState(false);
  const isFadeVariant = variant === 'fade';

  useEffect(() => {
    const cursor = document.querySelector('.custom-cursor');

    if (isDragged) {
      cursor?.classList.add('invisible');
    } else {
      cursor?.classList.remove('invisible');
    }
  }, [isDragged]);

  const opinions =
    quote.length > 1
      ? quote.map(({ id, card, description }, idx) => (
          <SwiperSlide key={`swiper-${id}-${idx}`}>
            <Opinion card={card} description={description} />
          </SwiperSlide>
        ))
      : quote.map(({ id, card, description }, idx) => (
          <FadeIn key={`swiper-${id}-${idx}`} custom={{ initial: { y: 100, opacity: 0 } }}>
            <Opinion card={card} description={description} />
          </FadeIn>
        ));

  const setTabIndexes: SwiperProps['onSlideChange'] = (swiper) => {
    swiper.navigation.prevEl?.addEventListener('click', () => {
      swiper.slides.forEach((slide) => {
        if (slide.getAttribute('class')?.includes(' swiper-slide-active')) {
          (slide.children[0] as HTMLElement).focus();
        }
      });
    });
    swiper.navigation.nextEl?.addEventListener('click', () => {
      swiper.slides.forEach((slide) => {
        if (slide.getAttribute('class')?.includes(' swiper-slide-active')) {
          (slide.children[0] as HTMLElement).focus();
        }
      });
    });

    swiper.slides.forEach((slide) => {
      if (slide.getAttribute('class')?.includes(' swiper-slide-active')) {
        slide.children[0].setAttribute('tabIndex', '0');
        slide.children[0].removeAttribute('aria-hidded');
        slide.children[0].setAttribute('aria-current', 'true');
      } else {
        slide.children[0].setAttribute('tabIndex', '-1');
        slide.children[0].setAttribute('aria-current', 'false');
        slide.children[0].setAttribute('aria-hidded', 'true');
      }
    });
  };

  return isFadeVariant ? (
    <Container contentWidth={1440} noPadding>
      <div {...{ className }}>
        {quote.length > 1 ? (
          <Swiper
            speed={2000}
            autoplay
            loop
            draggable={false}
            modules={[Autoplay, EffectFade]}
            effect="fade"
          >
            {opinions}
          </Swiper>
        ) : (
          opinions
        )}
      </div>
    </Container>
  ) : (
    <StyledSwiperWrapper {...{ className }}>
      <Typography
        size={24}
        fontWeight={500}
        color="gray-900"
        align="center"
        padding={{ bottom: 40 }}
      >
        {title}
      </Typography>
      {quote.length > 1 ? (
        <Swiper
          {...swiperProps}
          onTransitionEnd={setTabIndexes}
          onTouchStart={() => setIsDragged(true)}
          onTouchEnd={() => setIsDragged(false)}
        >
          <StyledArrowWrapper className="swiper-button-wrapper-prev swiper-left">
            <StyledArrowButton
              className="swiper-button-prev swiper-left"
              aria-label={previousOpinionButtonAriaLabel}
            >
              <Icon name="arrow-left" className="swiper-arrow" renderComponent />
            </StyledArrowButton>
          </StyledArrowWrapper>
          {opinions}
          <StyledArrowWrapper className="swiper-button-wrapper-next swiper-right">
            <StyledArrowButton
              className="swiper-button-next swiper-right"
              aria-label={nextOpinionButtonAriaLabel}
            >
              <Icon name="arrow-right" className="swiper-arrow" renderComponent />
            </StyledArrowButton>
          </StyledArrowWrapper>
        </Swiper>
      ) : (
        opinions
      )}
    </StyledSwiperWrapper>
  );
};

export default OurOpinion;
