import React, { useEffect, useRef, useState } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useScreenService } from 'hooks/useScreenService';

import {
  StyledContent,
  StyledDescription,
  StyledNumber,
  StyledRuleElement,
  StyledTitle,
} from './RuleElement.styles';

import { RuleElementProps } from './models.d';

const RuleElement = ({ title, number, description, index, className }: RuleElementProps) => {
  const [isVisible, setVisible] = useState(false);
  const featureRef = useRef<HTMLLIElement>(null!);
  const entry = useIntersectionObserver(featureRef, { freezeOnceVisible: false });
  const { isXlUp, isMdUp, isMobile } = useScreenService();
  const ruleGap = () => {
    if (isMobile) {
      return 36;
    }
    if (isMdUp && !isXlUp) {
      return 44;
    }
    if (isXlUp) {
      return 76;
    }

    return 0;
  };

  const onScroll = () => {
    if (featureRef.current) {
      const rect = featureRef?.current.getBoundingClientRect();
      const first = featureRef?.current.classList.contains('item-first');
      const last = featureRef?.current.classList.contains('item-last');

      if (first) {
        if (rect.top > window.innerHeight / 2) {
          featureRef?.current.classList.add('active');
        } else {
          featureRef?.current.classList.remove('active');
        }
      }
      if (last) {
        if (rect.bottom < window.innerHeight / 2) {
          featureRef?.current.classList.add('active');
        } else {
          featureRef?.current.classList.remove('active');
        }
      }

      setVisible(
        rect.top <= window.innerHeight / 2 + ruleGap() &&
          rect.bottom >= window.innerHeight / 2 - ruleGap()
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, entry]);

  return (
    <StyledRuleElement
      className={className}
      data-hover={isVisible}
      ref={featureRef}
      data-testid="rule-element"
      data-element={index}
    >
      <StyledContent>
        <StyledNumber
          asEl="span"
          size={{ base: 36, md: 60, xl: 72 }}
          fontWeight={700}
          color="gray-300"
        >
          {number}
        </StyledNumber>
        <StyledTitle
          asEl="h3"
          size={{ base: 30, md: 36, xl: 48 }}
          fontWeight={500}
          color="gray-800"
        >
          {title}
        </StyledTitle>
        {description ? (
          <StyledDescription size={{ base: 18, md: 20, xl: 24 }} fontWeight={400} color="gray-800">
            {renderRichText(description)}
          </StyledDescription>
        ) : null}
      </StyledContent>
    </StyledRuleElement>
  );
};
export default RuleElement;
