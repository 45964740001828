import styled from 'styled-components';
import { breakpoint } from 'styles';

import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

export const StyledHouseRulesContainer = styled(Container)`
  position: relative;

  &::before {
    display: none;
    position: absolute;
    bottom: 520px;
    left: 0;
    z-index: var(--z-index-zero);
    width: 100%;
    height: 221px;
    clip-path: polygon(0 0, 100% 0%, 100% 64%, 0% 100%);
    content: '';
    background: linear-gradient(360deg, var(--c-white) -3.85%, var(--c-transparent) 100%);

    ${breakpoint.md} {
      display: unset;
      bottom: 130px;
    }

    ${breakpoint.lg} {
      bottom: 44px;
      height: 418px;
      clip-path: polygon(0 0, 100% 0%, 100% 78%, 0% 100%);
    }

    ${breakpoint.xxl} {
      bottom: 28px;
      clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
    }

    ${breakpoint.xxxl} {
      bottom: -35px;
      clip-path: polygon(0 0, 100% 0%, 100% 40%, 0% 100%);
    }
  }
`;

export const StyledHouseRules = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledWrapper = styled.article`
  z-index: var(--z-index-zero);
  display: inline-flex;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const StyledDescription = styled(Typography)`
  b {
    font-weight: 500;
  }

  ${breakpoint.md} {
    max-width: 300px;
  }

  ${breakpoint.lg} {
    max-width: 510px;
  }
`;

export const StyledButton = styled(Button)`
  width: max-content;
  max-width: 100%;
  margin: var(--gap-xxxxl) auto 0;
  align-self: center;

  ${breakpoint.md} {
    margin: var(--gap-md) 0 0;
  }
`;

export const StyledHeaderContainer = styled.header`
  margin-bottom: 48px;

  ${breakpoint.md} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const StyledHeader = styled.header`
  ${breakpoint.md} {
    position: sticky;
    top: 40%;
  }
`;

export const StyledRulesListContainer = styled.article``;

export const StyledRulesList = styled.ul`
  display: grid;
  row-gap: var(--gap-xxxxl);

  ${breakpoint.md} {
    width: 50%;
    row-gap: var(--gap-xxxxxxl);
  }

  ${breakpoint.xl} {
    row-gap: 152px;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 814px;
  margin: var(--gap-xxl) auto 0;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

  ${breakpoint.md} {
    margin: 152px auto 0;
  }

  ${breakpoint.xxxl} {
    max-width: 842px;
  }
`;
