import React from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Container from 'components/molecules/Container';
import PhotoGallery from 'components/molecules/PhotoGallery';
import HouseRules from 'components/organisms/HouseRules';
import { StyledHouseRulesContainer } from 'components/organisms/HouseRules/HouseRules.styles';
import OurOpinion from 'components/organisms/OurOpinion';
import RelatedList from 'components/organisms/RelatedList';
import TechnologiesSection, {
  TechnologiesSectionVariant,
} from 'components/organisms/TechnologiesSection';
import { StyledAboutUsHeading } from 'components/templates/containers';
import Layout from 'components/templates/Layout';

const CaseStudiesListingPage = ({
  data: {
    contentfulAboutUsPage: {
      relatedList,
      rulesSection,
      customerSatisfactionSection,
      gallery,
      banner,
      seo,
      technologiesSection,
    },
  },
}: AboutUsPageTypes.AboutUsPageProps) => (
  <Layout className="about-us-page" seo={seo} bgColor="gray-50">
    <Container contentWidth={1440}>
      <StyledAboutUsHeading
        lineHeight={1.25}
        fontWeight={300}
        size={{ base: 36, md: 48, lg: 60, xl: 72 }}
        padding={{
          top: { base: 72, md: 128, lg: 120 },
          right: { base: 0, md: 104, lg: 180, xl: 395 },
          bottom: { base: 40, md: 96, lg: 136, xl: 160 },
          left: { base: 0, xl: 80 },
        }}
        letterSpacing="-0.02em"
        color="gray-800"
      >
        {renderRichText(banner.heading)}
      </StyledAboutUsHeading>
    </Container>
    <PhotoGallery {...gallery} />
    {technologiesSection ? (
      <TechnologiesSection {...technologiesSection} variant={TechnologiesSectionVariant.SERVICES} />
    ) : null}
    <StyledHouseRulesContainer contentWidth={1280}>
      <HouseRules {...rulesSection} />
    </StyledHouseRulesContainer>
    <Container mt={{ base: 40, md: 80 }} pb={{ base: 96, md: 144, lg: 184, xl: 200 }}>
      <OurOpinion {...customerSatisfactionSection} variant="slide" />
    </Container>
    <Container
      pb={{ base: 80, md: 96, lg: 136, xl: 200 }}
      mt={{ base: 80, md: 96, lg: 136, xl: 200 }}
    >
      <RelatedList
        variant={relatedList.variant}
        heading={relatedList.title}
        list={relatedList.relatedSites}
        proportions={relatedList.proportions}
        button={relatedList.button?.[0]}
      />
    </Container>
  </Layout>
);

export default CaseStudiesListingPage;

export const query = graphql`
  query {
    contentfulAboutUsPage {
      seo {
        ...SeoFragment
      }
      banner {
        heading {
          raw
        }
      }
      teamCarousel {
        ...RelatedListFragment
      }
      relatedList {
        ...RelatedListFragment
      }
      rulesSection {
        ...RulesSectionFragment
      }
      customerSatisfactionSection {
        title
        quote {
          description {
            raw
          }
          card {
            title
            subtitle
            image {
              ...ImageDataStructureFragment
            }
          }
        }
      }
      gallery {
        ...PhotoGalleryFragment
      }
      technologiesSection {
        ...TechnologiesSectionFragment
      }
    }
  }
`;
