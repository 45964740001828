import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useSwiper } from 'swiper/react';

import {
  opinionContentTypography,
  opinionSubtitleTypography,
  opinionTitleTypography,
} from './Opinion.constants';
import {
  StyledOpinion,
  StyledOpinionCard,
  StyledOpinionContent,
  StyledOpinionImage,
  StyledOpinionSubtitle,
  StyledOpinionTitle,
} from './Opinion.styles';

import { OpinionType } from './models.d';

const Opinion = ({
  className,
  description,
  card: {
    title,
    subtitle,
    image: { alt, image },
  },
}: OpinionType) => {
  const swiper = useSwiper();

  return (
    <StyledOpinion
      {...{ className }}
      data-testid="opinion"
      onFocus={() => {
        swiper.autoplay.stop();
      }}
      tabIndex={0}
    >
      {typeof description === 'string' ? (
        <StyledOpinionContent {...opinionContentTypography} dangerouslySetInnerHTML={description} />
      ) : (
        <StyledOpinionContent {...opinionContentTypography}>
          {renderRichText(description)}
        </StyledOpinionContent>
      )}
      <StyledOpinionCard>
        <StyledOpinionImage {...{ alt, image, objectFit: 'contain' }} />
        <StyledOpinionTitle {...opinionTitleTypography}>{title}</StyledOpinionTitle>
        <StyledOpinionSubtitle {...opinionSubtitleTypography}>{subtitle}</StyledOpinionSubtitle>
      </StyledOpinionCard>
    </StyledOpinion>
  );
};

export default Opinion;
