import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';

export const StyledOpinion = styled.div`
  z-index: var(--z-index-ground);
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  align-items: center;

  ${breakpoint.md} {
    align-items: center;
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }
`;

export const StyledOpinionContent = styled(Typography)`
  width: 100%;
  max-width: 778px;
`;

export const StyledOpinionCard = styled.div`
  display: grid;
  grid-template-areas: 'image title' 'image subtitle';
  grid-template-columns: var(--gap-xxxl) 1fr;
  row-gap: var(--gap-xxxxxs);
  column-gap: var(--gap-xxs);

  ${breakpoint.md} {
    grid-template-columns: 92px 1fr;
    column-gap: var(--gap-xl);
  }
`;

export const StyledOpinionImage = styled(Image)`
  grid-area: image;
  max-height: unset;
`;

export const StyledOpinionTitle = styled(Typography)`
  grid-area: title;
`;

export const StyledOpinionSubtitle = styled(Typography)`
  grid-area: subtitle;
`;
