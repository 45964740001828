import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';

export const StyledNumber = styled(Typography)`
  letter-spacing: var(--font-letter-spacing);
`;

export const StyledImage = styled(Image)`
  height: 245px;

  ${breakpoint.md} {
    height: unset;
    max-height: 380px;
  }
`;

export const StyledTitle = styled(Typography)`
  letter-spacing: var(--font-letter-spacing);
`;

export const StyledDescription = styled(Typography)`
  letter-spacing: var(--font-letter-spacing);
`;

export const StyledRuleElement = styled.li`
  display: grid;
  gap: var(--gap-xxs);
  opacity: 0.3;

  &:hover,
  &[data-hover='true'],
  &.active {
    opacity: 1;
  }

  ${breakpoint.md} {
    &[data-element='0'] {
      ${StyledImage} {
        transform: translateX(80px);
      }
    }

    &[data-element='1'] {
      ${StyledImage} {
        transform: translateX(-30px);
      }
    }

    &[data-element='2'] {
      ${StyledImage} {
        transform: translateX(50px);
      }
    }

    &[data-element='3'] {
      ${StyledImage} {
        transform: translateX(20px);
      }
    }

    &[data-element='4'] {
      ${StyledImage} {
        transform: translateX(-20px);
      }
    }
  }
`;

export const StyledContent = styled.article`
  display: grid;
  gap: var(--gap-xxs);
  place-content: start;
`;
