import { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperProps } from 'swiper/react';

export const slideDuration = 0.5;

export const swiperProps: SwiperProps = {
  modules: [Autoplay, Pagination, Navigation],
  navigation: {
    nextEl: '.swiper-button-next, .swiper-button-wrapper-next',
    prevEl: '.swiper-button-prev, .swiper-button-wrapper-prev',
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 8,
    },
    1024: {
      slidesPerView: 1.5,
      spaceBetween: 16,
    },
    1440: {
      slidesPerView: 1.8,
      spaceBetween: 80,
    },
    1920: {
      slidesPerView: 2,
      spaceBetween: 160,
    },
    2560: {
      slidesPerView: 2.4,
      spaceBetween: 240,
    },
  },
  autoplay: true,
  speed: slideDuration * 1000,
  loop: true,
  centeredSlides: true,
  pagination: { clickable: true },
};
